import Vue from 'vue'
import Router from 'vue-router'

// import { MarkdownInserter } from './components/MarkdownInserter'
import DynMarkdown from './views/DynMarkdown.vue'

import { setLogger, log } from '../../common/lib/ABCCommon'
import { ABCLogger, logger } from '../../common/lib/ABCLogger'
/* Enable Global Logging before anything else */
// eslint-disable-next-line
const l = new ABCLogger('debug')
setLogger(logger)
log.info('Main.ts has been entered for Pages')
log.debug('LOG IS IN DEBUG MODE')
console.log('in main')
console.log('in router ')
log.debug('LOG IS IN DEBUG MODE IN ROUTER')

// Static markdown pages
import Home from './views/Home.vue'
import BlogList from './views/BlogList.vue'
import Foo from '!raw-loader!./markdowns/test.md'
import About from '!raw-loader!./markdowns/about.md'
import Links from '!raw-loader!./markdowns/links.md'
import Faq from '!raw-loader!./markdowns/faq.md'
import Download from '!raw-loader!./markdowns/download.md'
import Contribute from '!raw-loader!./markdowns/contribute.md'
import Documentation from '!raw-loader!./markdowns/documentation.md'
// import About from './views/About.vue'
import jsonRoutes from './statics/data/blogs.json'
// Blog pages
import v1003 from '!raw-loader!./markdowns/2021/version-1.0.3.md'
Vue.use(Router)
log.debug(`json: ${JSON.stringify(jsonRoutes)}`)
log.debug(`foo: ${Foo}`)

const originalPush = Router.prototype.push
Router.prototype.push = async function (location: any) {
  let route: any
  try {
    route = await originalPush.call<Router, [any], Promise<any>>(this, location)
  } catch (err) {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  }
  return route
}
interface BlogRoute {
  id: string
  date: string
  title: string
  description: string
}

// const jsonRoutes: BlogRoutes = require('./statics/data/blogs.json')

const blogRoutes: any = Object.keys(jsonRoutes).map((section) => {
  const children = (jsonRoutes as any)[section].map((child: BlogRoute) => ({
    path: child.id,
    name: child.id,
    component: DynMarkdown,
    props: () => {
      switch (child.id) {
        case 'v1003':
          return { source: v1003 }
      }
    }
  }))
  return {
    path: `/${section}`,
    name: section,
    component: () => import('./views/Blog.vue'),
    children
  }
})

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/foo',
      name: 'foo',
      component: DynMarkdown,
      props: { source: Foo }
    },
    {
      path: '/about',
      name: 'about',
      component: DynMarkdown,
      props: { source: About }
    },
    {
      path: '/faq',
      name: 'faq',
      component: DynMarkdown,
      props: { source: Faq }
    },
    {
      path: '/versions',
      name: 'versions',
      component: BlogList
    },
    {
      path: '/download',
      name: 'download',
      component: DynMarkdown,
      props: { source: Download }
    },
    {
      path: '/links',
      name: 'links',
      component: DynMarkdown,
      props: { source: Links }
    },
    {
      path: '/contribute',
      name: 'contribute',
      component: DynMarkdown,
      props: { source: Contribute }
    },
    {
      path: '/documentation',
      name: 'documentation',
      component: DynMarkdown,
      props: { source: Documentation }
    },
    // eslint-disable-next-line
    ...blogRoutes
  ]
})
