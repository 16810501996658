






























import { Component, Prop, Vue } from 'vue-property-decorator'
import VueMarkdown from '@adapttive/vue-markdown'
import { MarkdownInserter } from './MarkdownInserter'
import router from '../router'
import { logger } from '../../../common/lib/ABCLogger'

import BlogList from '../views/BlogList.vue'

// eslint-disable-next-line
import Foo from '!raw-loader!../markdowns/test.md'

// eslint-disable-next-line
@Component({
  components: {
    VueMarkdown,
    BlogList
  }
})
export default class HomePage extends Vue {
  @Prop() private msg!: string

  mounted() {
    const inserter = new MarkdownInserter()
    inserter.insert(this.$refs.markInsert as HTMLElement, Foo)
  }

  goDownload() {
    logger.debug('going download')
    router.push({ name: 'download' })
  }
}
