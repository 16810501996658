



import { Component, Vue, Prop } from 'vue-property-decorator'
import { MarkdownInserter } from '../components/MarkdownInserter'

@Component
export default class DynMarkdown extends Vue {
  @Prop({ default: null })
  source!: string
  mounted() {
    console.log('Mount dynmarkdown entered')
    if (this.source != null) {
      const inserter = new MarkdownInserter()
      inserter.insert(this.$refs.dynmarkdown as HTMLElement, this.source)
    }
  }
}
