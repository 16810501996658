
















import { Vue, Component } from 'vue-property-decorator'
import PageHeader from './components/PageHeader.vue'
import PageFooter from './components/PageFooter.vue'

// global style import
import './sass/global.scss'

@Component({
  components: {
    PageHeader,
    PageFooter
  }
})
export default class MyApp extends Vue {
  mounted() {
    console.log('MainApp Vue Mounted')
    // this.addClass('has-navbar-fixed-top')
  }
}
