import { render, staticRenderFns } from "./BlogList.vue?vue&type=template&id=4172c095&scoped=true&"
import script from "./BlogList.vue?vue&type=script&lang=ts&"
export * from "./BlogList.vue?vue&type=script&lang=ts&"
import style0 from "./BlogList.vue?vue&type=style&index=0&id=4172c095&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4172c095",
  null
  
)

export default component.exports