




































































































import { Vue, Prop, Component } from 'vue-property-decorator'
import PageIcon from './PageIcon.vue'
import { logger } from '../../../common/lib/ABCLogger'
import router from '../router'

@Component({
  components: {
    PageIcon
  }
})
export default class PageHeader extends Vue {
  @Prop({ default: null })
  tittle: string
  selectedItem: HTMLElement | null = null
  burgerOpen = false

  goGitlab() {
    logger.debug('going gitlab')
    window.open('https://gitlab.com/jymen/abcmusicstudio')
  }

  goHome() {
    logger.debug('going Home')
    router.push({ name: 'home' })
  }

  burgerClicked() {
    console.log('burger clicked')
    this.burgerOpen = !this.burgerOpen
  }

  handleSelectedItem(e: MouseEvent) {
    if (this.selectedItem != null) {
      this.selectedItem.classList.remove('is-active')
    }
    this.selectedItem = e.currentTarget as HTMLElement
    this.selectedItem.classList.add('is-active')
    if (this.selectedItem.id == 'bugs') {
      window.open('https://gitlab.com/jymen/abcmusicstudio/-/issues')
    } else {
      router.push({ name: this.selectedItem.id })
    }
  }
}
