














import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class PageIcon extends Vue {
  @Prop({ default: null }) iconName: string
  @Prop({ default: null }) size: string
  @Prop({ default: null }) clicked: () => void
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) small: boolean
}
