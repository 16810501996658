import Vue from 'vue'
// Global logger has been inited in router.ts
import { log } from '../../common/lib/ABCCommon'
log.info('Main.ts has been entered for Pages')
log.debug('LOG IS IN DEBUG MODE')
console.log('in main')

import MyApp from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// global style import
import './sass/global.scss'

//
// Icons import
//
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core'

import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faGitlab } from '@fortawesome/fontawesome-free-brands'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faGitlab as IconDefinition)
library.add(faHome)

Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  render: (h) => h(MyApp)
}).$mount('#app')
