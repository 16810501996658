








import { Prop, Vue, Component } from 'vue-property-decorator'

@Component
export default class PageFooter extends Vue {
  @Prop({ default: null }) message: string

  mounted() {}
}
