





























import { Component, Vue } from 'vue-property-decorator'

import BlogEntries from '@/statics/data/blogs.json'

@Component({})
export default class BlogList extends Vue {
  get entries() {
    return BlogEntries
  }

  entryClick(id: string) {
    console.log('click for blog')
    this.$router.push({ name: id })
  }
}
