/*
Dynamically insert a markdown
*/
import Vue from 'vue'
import VueMarkdown from '@adapttive/vue-markdown'
import { log } from '../../../common/lib/ABCCommon'

class MarkdownInserter {
  constructor() {}

  getComponentClass(): any {
    const componentClass = Vue.extend(VueMarkdown)
    return componentClass
  }

  emitHtmlView(markdown: string): any {
    const componentClass = this.getComponentClass()
    log.debug(`markdown : ${markdown}`)
    const instance = new componentClass({
      propsData: {
        source: markdown
      }
    })
    return instance
  }

  /* dynamic insert in parent element */
  insert(parent: HTMLElement, markdown: string) {
    const instance = this.emitHtmlView(markdown)
    instance.$mount()
    if (parent != undefined) {
      parent.appendChild(instance.$el)
    }
  }
}

export { MarkdownInserter }
